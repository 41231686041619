@import '~xp.css/dist/98.css';
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

summary {
    font-size: small;
    font-weight: 1000;
}

#tabMenuWrapper {
    padding: 1%;
}

.bg-custom-ailensweeper {
    --tw-bg-opacity: 1;
    background-color: #262038;
}

.color-custom-ailensweeper-dark {
    --tw-bg-opacity: 1;
    color: #990077;
}

.color-custom-ailensweeper-shine {
    --tw-bg-opacity: 1;
    color: #cc0077;
}

.bg-custom-tetris {
    --tw-bg-opacity: 1;
    background-color: #c1edd9;
}

.bg-custom-tetris-dark {
    --tw-bg-opacity: 1;
    background-color: #58c9e5;
}

.color-custom-tetris-dark {
    --tw-bg-opacity: 1;
    color: #0c5885;
}

.color-custom-tetris-light {
    --tw-bg-opacity: 1;
    color: #298bc5;
}

.border-custom-100 {
    --tw-border-opacity: 1;
    border-color: rgba(62, 71, 86, var(--tw-border-opacity));
}

.font-custom {
    font-family: 'Open Sans', sans-serif;
}

.shadow-custom-1 {
    box-shadow: -1px -1px 2px 3px #303030;
}

.bg-separador-1 {
    background-image: url('../public/images/separador_1.png');
    background-size: contain;
}

.bg-separador-2 {
    background-image: url('../public/images/separador_2.png');
    background-size: contain;
}

.bg-separador-3 {
    background-image: url('../public/images/separador_3.png');
    background-size: contain;
}