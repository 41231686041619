.sidebar-layout {
  display: grid;
  gap: 8px;
  grid-template-rows: auto;
  grid-template-columns: auto 1fr;
  width: 100%;
  height: 100%;
}

#img_holder{
  width: auto;
  height: auto;
}

.sidebar{
  max-width: 128px;
}
.content > #img_holder > a > img {
  display: block;
  min-height: 0;
  max-width: 192px;
  max-height: 255px;
  width: auto;
  height: auto;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 0;
}