#tetris_component {
    display: grid;
    gap: 8px;
    width: 100%;
}

@media screen and (orientation: portrait) and (max-height: 420px) {
    #tetris_component {
        grid-template-rows: auto 1fr;
        height: 90vh;
    }
    #tetris_viewport,
    #instructions {
        max-width: 100%;
        max-height: 100%;
    }
    #instructions {
        grid-template-columns: auto auto;
    }
}

@media screen and (orientation: landscape) and (min-width: 512px) {
    #tetris_component {
        grid-template-columns: auto 1fr;
        height: 69vh;
    }
    #tetris_viewport,
    #instructions {
        max-width: 50%;
    }
    #instructions {
        grid-template-columns: auto 1fr;
    }
}

#tetris_viewport,
#instructions {
    max-height: 90%;
    display: grid;
}