#djikstra_viewport {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -50;
}

#game_board {
    width: 100%;
    height: 100%;
}