.window{
    width: 300px;
    max-width: 82%;
}

.hidden{
    display: none;
}

#mail-form{
    display: grid;
    grid-template-rows: auto;
    width: 100%;
}

.input-wrapper{
    width: 100%;
    display: grid;
    grid-template-columns: 27% auto;
}

#message{
    min-height: 69px;
}