@font-face {
    font-family: Market-Deco;
    src: url("../assets/Market_Deco.ttf");
}

#viewport {
    height: 69vh;
    width: 100%;
    z-index: 100;
}

#gamebar {
    height: 10%;
    position: absolute;
}

.hide{
    display: none;
}